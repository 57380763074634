import accordion from './accordion/accordion.css?url'
import anchorReveal from './anchor-reveal/anchor-reveal.css?url'
import hero from './hero/hero.css?url'
import section from './section/section.css?url'
import stack from './stack/stack.css?url'
import figure from './figure/figure.css?url'

export const styles = {
  anchorReveal,
  accordion,
  figure,
  hero,
  section,
  stack,
}

/**
 * Web site layout
 **/
// export { Footer } from './footer/footer'
// export { Header } from './header/header'
export { Flash } from './flash/flash'

/**
 * Page Layout
 */
export { Hero } from './hero/hero'
export { Section } from './section/section'

export { Accordion } from './accordion/accordion'
export { AnchorReveal } from './anchor-reveal/anchor-reveal'
export { Figure } from './figure/figure'
export { Quote } from './quote/quote'
export { Stack } from './stack/stack'
export type { StackProps } from './stack/stack'
export * from './text'
export { Card } from './card/card'
export {
  CallOutList,
  CallOutText,
  CallOutIllustration,
} from './callout-section/callout-section'
